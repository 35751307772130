@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
}

@media print {
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: always;
    }
    .page-break-image-1 {
        margin-top: 1rem;
    }
    .page-break-image-2 {
        margin-top: 20rem;
    }
    .page-break-image-3 {
        margin-top: 33rem;
    }
    .page-break-image-4 {
        margin-top: 33rem;
    }
    .page-break-image-5 {
        margin-top: 40rem;
    }
    .page-break-image-6 {
        margin-top: 40rem;
    }
    .page-break-image-7 {
        margin-top: 30rem;
    }
    .page-break-image-8 {
        margin-top: 35rem;
    }
    .page-break-image-9 {
        margin-top: 33rem;
    }
    .page-break-image-10 {
        margin-top: 33rem;
    }
    .qtd {
        margin-top: 55rem;
        page-break-before: always;
    }
    .qt-page-break-image-1 {
        margin-top: 1rem;
    }
    .qt-page-break-image-2 {
        margin-top: 30rem;
    }
    .qt-page-break-image-3 {
        margin-top: 35rem;
    }
    .qt-page-break-image-4 {
        margin-top: 35rem;
    }
    .qt-page-break-image-5 {
        margin-top: 35rem;
    }
    .qt-page-break-image-6 {
        margin-top: 35rem;
    }
    .qt-page-break-image-7 {
        margin-top: 35rem;
    }
    .qt-page-break-image-8 {
        margin-top: 35rem;
    }
    .qt-page-break-image-9 {
        margin-top: 35rem;
    }
    .qt-page-break-image-10 {
        margin-top: 35rem;
    }

}

@page {
	size: auto;
	margin: 10mm;
}

#carscan-360 {
    width: 100%;
    padding: 10px;
}